import { Button, CircularProgress, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import { Autocomplete, Checkbox, FormButtons, Loading, Select, TextField } from 'components/form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useSelector } from 'react-redux';
import { FormView } from '../../../components/DataTable';
import { trimWhitespaces } from '../../../components/form/formUtils';
import { useState } from '../../../hooks';
import useQuery from '../../../hooks/useQuery';
import { maxLength, minLength, required, validators } from '../../../utils/validate';
import { getActiveClientConnections } from '../../api/clientconnections';
import {
    createClientDbAppLicense,
    getDbAppLicence,
    updateClientDbAppLicense,
} from '../../api/clientDbAppLicense';
import {
    createClientDb,
    getClientDb,
    pingClientDb,
    updateClientDb,
    updateProc,
} from '../../api/clientdbs';
import { isAdmin, isDealer, isClient } from '../../auth/selectors';
import { useNotifications } from '../../notifications';
import ApiKeysForm from './ApiKeysForm';
import ClientDbAppLicencesForm from './ClientDbAppLicencesForm';
import ClientDbAppUsersForm from './ClientDbAppUsersForm';
import ClientDbSaveConfirm from './ClientDbSaveConfirm';
import {
    appLicencesValidation,
    usersQuantityByLicencesValidation,
    userUniquinessValidation,
} from './formUtils';

const useStyles = makeStyles((theme) => ({
    noLabelControl: {
        marginTop: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    spinner: {
        marginLeft: theme.spacing(1),
    },
    tabContainer: {
        border: '1px solid #e9e9e9',
        padding: theme.spacing(2),
    },
}));

const useTabItemStyles = makeStyles({
    root: {
        border: '1px solid #e9e9e9',
        '&:not(:first-of-type)': {
            marginLeft: -1,
        },
        background: '#f7f7f7',
        opacity: 1,
    },
    selected: {
        borderBottomWidth: 0,
        background: '#ffffff',
        '& $wrapper': {
            opacity: 1,
        },
    },
    wrapper: {
        opacity: 0.7,
    },
});

const sqlOptions = [
    { value: 'MS_SQL', label: 'MS SQL Server' },
    { value: 'SYBASE', label: 'SQL Anywhere (SYBASE)' },
];

const formSubscription = {};

function ClientDbsForm(props) {
    const { values, onSuccess, onError, onClose, ...formViewProps } = props;
    const clientDbId = values.id;
    const isEdit = !!clientDbId;
    const [clientConnId, setClientConnId] = useState();
    const [dbLincenses, setDbLincenses] = useState([]);
    const [clientId, setClientId] = useState(values.clientId);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const classes = useStyles();
    const tabClasses = useTabItemStyles();
    const notifications = useNotifications();
    const isUserAdmin = useSelector(isAdmin);
    const isUserDealer = useSelector(isDealer);
    const isUserClient = useSelector(isClient);

    const [tab, setTab] = useState(0);
    const [clientDb, setClientDb] = useState(values);

    React.useEffect(() => {
        if (!clientDbId) {
            return;
        }

        getClientDb(clientDbId)
            .then((data) => {
                if (data.apiKeysList) {
                    data.apiKeysList.sort((a, b) => a.id - b.id);
                }
                setClientDb(data);
            })
            .catch((reason) => {
                const msg = (reason.response && reason.response.data.message) || reason.message;
                notifications.showError(msg);
            });
    }, [clientDbId, notifications]);

    React.useEffect(() => {
        if (!clientDbId) {
            return;
        }
        getDbAppLicence(clientDbId)
            .then((data) => {
                if (data.data) setDbLincenses(data.data);
            })
            .catch((reason) => {
                const msg = (reason.response && reason.response.data.message) || reason.message;
                notifications.showError(msg);
            });
    }, [clientDbId, notifications]);

    const openSaveConfirm = React.useCallback(() => {
        return new Promise((resolve) => {
            setSaveConfirm({ resolve });
        });
    }, []);

    // initial form values
    const initialValues = React.useMemo(
        () => ({
            clientConnectionId: values.clientConnection ? values.clientConnection.id : '',
            name: values.name || '',
            companyName: values.companyName || '',
            description: values.description || '',
            type: values.type || '',
            companyId: values.companyId || '',
            currencyCode: values.currencyCode || '',
            isActive: !!values.isActive,
            apiKeysList: clientDb.apiKeysList || [],
            clientDbUserApplications: clientDb ? clientDb.clientDbUserApplications : [],
            clientDbAppLicense: dbLincenses ? dbLincenses : [],
        }),
        [values, clientDb, dbLincenses],
    );

    // on valid form submit callback
    const onSubmit = React.useCallback(
        async (values) => {
            // in edit mode delete 'generated' flag from apiKeyList which was used in ApiKeysForm
            if (clientDbId) {
                values.apiKeysList.forEach((apiKey) => {
                    delete apiKey.generated;

                    if (apiKey.validToDate)
                        apiKey.validToDate = apiKey.validToDate.substring(0, 10);
                });
            }

            //when generating new user 'isNew' flag was added.
            if (values.clientDbUserApplications) {
                values.clientDbUserApplications.forEach((user) => {
                    user.clientDbId = clientDbId;
                    delete user.isNew;
                });
            }

            let dbValues = {
                ...values,
                companyId: values.companyId.toUpperCase(),
                currencyCode: values.currencyCode.toUpperCase(),
            };

            if (
                dbValues.companyId !== initialValues.companyId ||
                dbValues.currencyCode !== initialValues.currencyCode
            ) {
                const result = await openSaveConfirm();
                setSaveConfirm(false);
                if (result === false) return;
            }

            let licenseValues = [];
            //different API for db info and licenses info.
            if (values.clientDbAppLicense) {
                licenseValues = [...values.clientDbAppLicense];
                delete dbValues.clientDbAppLicense;
            }

            const lisenceCall = async (id) => {
                if (licenseValues.length > 0) {
                    for (const license of licenseValues) {
                        let licenseCount = parseInt(license.licenseCount);
                        if (isNaN(licenseCount)) licenseCount = 0;

                        // let plannedCount = parseInt(license.plannedCount);
                        // if (isNaN(plannedCount)) plannedCount = 0;

                        //2024 10 08 changes - PlannedCount is always 0

                        const params = {
                            applicationName: license.applicationName,
                            clientDbId: id,
                            licenseCount: licenseCount,
                            plannedCount: 0,
                        };

                        if (license.isNew) {
                            await createClientDbAppLicense(params);
                        } else {
                            await updateClientDbAppLicense(license.id, params);
                        }
                    }
                }
                return;
            };

            try {
                let clientDbData = {};
                if (clientDbId) {
                    await lisenceCall(clientDbId);
                    clientDbData = await updateClientDb(clientDbId, dbValues);
                } else {
                    clientDbData = await createClientDb(dbValues);
                    await lisenceCall(clientDbData.id);
                    clientDbData = await updateClientDb(clientDbData.id, dbValues);
                }

                const warnings = clientDbData.warnings;
                let warningMsg = '';

                if (warnings && Array.isArray(warnings) && warnings.length > 0) {
                    warnings.forEach((item) => (warningMsg += item + ' '));
                }

                const msg = clientDbId
                    ? `Kliento DB '${values.name}' duomenys atnaujinti.`
                    : `Kliento DB '${values.name}' sukurta sėkmingai.`;

                if (warningMsg) {
                    notifications.showWarning(warningMsg);
                } else notifications.showSuccess(msg);

                onSuccess(clientDbData);
                onClose();
            } catch (reason) {
                const msg = (reason.response && reason.response.data.message) || reason.message;
                notifications.showError(msg);
                onError(reason);
            }
        },
        [
            clientDbId,
            initialValues.companyId,
            initialValues.currencyCode,
            openSaveConfirm,
            notifications,
            onSuccess,
            onClose,
            onError,
        ],
    );

    const { data, isLoading, error } = useQuery(getActiveClientConnections);
    const clientConnectionOptions = React.useMemo(() => {
        let options = data ? data.data : [];
        if (clientId && data) options = data.data.filter((conn) => conn.clientId === clientId);

        return options.map((conn) => ({
            label: (conn.client ? conn.client.name : '') + ' - ' + conn.name + ' (' + conn.id + ')',
            value: conn.id,
        }));
    }, [data, clientId]);

    React.useEffect(() => {
        if (!clientConnId) {
            return;
        }

        const connection = data ? data.data.filter((conn) => conn.id === clientConnId) : [];

        if (connection) {
            setClientId(connection[0].client.id);
        }
    }, [clientConnId, data]);

    const [testInProgress, setTestInProgress] = useState(false);
    const testConnection = React.useCallback(() => {
        setTestInProgress(true);
        pingClientDb(clientDbId)
            .then((response) => notifications.showSuccess(response.message))
            .catch((e) => {
                const msg = e.response ? e.response.data.message : e.message;
                notifications.showError(msg);
            })
            .finally(() => setTestInProgress(false));
    }, [clientDbId, notifications]);

    const [updatingProcedures, setUpdatingProcedures] = useState(false);
    const updateProcedures = React.useCallback(() => {
        setUpdatingProcedures(true);
        updateProc(clientDbId)
            .then((response) => notifications.showSuccess(response.message))
            .catch((e) => {
                const msg = e.response ? e.response.data.message : e.message;
                notifications.showError(msg);
            })
            .finally(() => setUpdatingProcedures(false));
    }, [clientDbId, notifications]);

    // if dealers are still being loaded show form loading view
    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        notifications.showError(error.message);
        onError(error);
        onClose();
        return;
    }

    return (
        <>
            <FormView
                title="Kliento duomenų bazė"
                onClose={onClose}
                maxWidth="lg"
                {...formViewProps}
            >
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    subscription={formSubscription}
                    mutators={{ ...arrayMutators }}
                    validate={validators(
                        userUniquinessValidation,
                        appLicencesValidation,
                        usersQuantityByLicencesValidation,
                    )}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid spacing={1} container>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        label=" Kliento jungtis"
                                        name="clientConnectionId"
                                        items={clientConnectionOptions}
                                        validate={required}
                                        disabled={isEdit && !isUserAdmin}
                                        required
                                    />

                                    <OnChange name="clientConnectionId">
                                        {(connId) => setClientConnId(connId)}
                                    </OnChange>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="companyName"
                                        label="DB įmonė"
                                        validate={required}
                                        disabled={
                                            !(
                                                !isEdit ||
                                                isUserAdmin ||
                                                isUserDealer ||
                                                isUserClient
                                            )
                                        }
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="name"
                                        label="DB vardas"
                                        validate={required}
                                        disabled={!(!isEdit || isUserAdmin || isUserDealer)}
                                        required
                                        parse={trimWhitespaces}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Select
                                        label="SQL tipas"
                                        name="type"
                                        items={sqlOptions}
                                        validate={required}
                                        disabled={!(!isEdit || isUserAdmin || isUserDealer)}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="companyId"
                                        label="Įmonės ID"
                                        validate={validators(required, minLength(2), maxLength(2))}
                                        disabled={!(!isEdit || isUserAdmin || isUserDealer)}
                                        required
                                        parse={trimWhitespaces}
                                        uppercase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="currencyCode"
                                        label="Valiuta"
                                        validate={validators(required, maxLength(12))}
                                        disabled={!(!isEdit || isUserAdmin || isUserDealer)}
                                        required
                                        parse={trimWhitespaces}
                                        uppercase
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        name="description"
                                        label="Web adresas"
                                        multiline
                                        validate={required}
                                        disabled={
                                            !(
                                                !isEdit ||
                                                isUserAdmin ||
                                                isUserDealer ||
                                                isUserClient
                                            )
                                        }
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Checkbox
                                        name="isActive"
                                        label="Aktyvus"
                                        formControlLabelClasses={{
                                            root: classes.noLabelControl,
                                        }}
                                    />
                                </Grid>

                                {isEdit && (
                                    <React.Fragment>
                                        <Grid item xs={12} container justify="flex-end" spacing={1}>
                                            {(isUserAdmin || isUserDealer) && (
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={updateProcedures}
                                                        disabled={updatingProcedures}
                                                    >
                                                        Atnaujinti Procedūras{' '}
                                                        {updatingProcedures && (
                                                            <CircularProgress
                                                                size={20}
                                                                className={classes.spinner}
                                                            />
                                                        )}
                                                    </Button>
                                                </Grid>
                                            )}

                                            <Grid item>
                                                <Button
                                                    variant="outlined"
                                                    onClick={testConnection}
                                                    disabled={testInProgress}
                                                >
                                                    Tikrinti ryšį{' '}
                                                    {testInProgress && (
                                                        <CircularProgress
                                                            size={20}
                                                            className={classes.spinner}
                                                        />
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}

                                <Grid item xs={12} container>
                                    <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
                                        <Tab label="Api raktai" classes={tabClasses} />
                                        <Tab label="Vartotojai" classes={tabClasses} />
                                        <Tab label="Licencijos" classes={tabClasses} />
                                    </Tabs>

                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: tab === 0 ? 'block' : 'none' }}
                                    >
                                        <div className={classes.tabContainer}>
                                            <ApiKeysForm
                                                fieldName="apiKeysList"
                                                isEdit={isEdit}
                                                clientConnections={data}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: tab === 1 ? 'block' : 'none' }}
                                    >
                                        <div className={classes.tabContainer}>
                                            <ClientDbAppUsersForm
                                                fieldName="clientDbUserApplications"
                                                clientId={clientId}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: tab === 2 ? 'block' : 'none' }}
                                    >
                                        <div className={classes.tabContainer}>
                                            <ClientDbAppLicencesForm fieldName="clientDbAppLicense" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <FormButtons onCancel={onClose} />
                        </form>
                    )}
                />
            </FormView>
            {saveConfirm ? <ClientDbSaveConfirm resolve={saveConfirm.resolve} /> : null}
        </>
    );
}

ClientDbsForm.defaultProps = {
    values: {},
};

ClientDbsForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    values: PropTypes.shape({
        clientConnectionId: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        companyId: PropTypes.string,
        currencyCode: PropTypes.string,
        isActive: PropTypes.bool,
        apiKeysList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                prefix: PropTypes.string,
                apiKey: PropTypes.string,
                clientDbId: PropTypes.number,
                validToDate: PropTypes.string,
                createdAt: PropTypes.string,
                updatedAt: PropTypes.string,
            }),
        ),
    }),
};

export default React.memo(ClientDbsForm);
